import { getSubdomainFromHost } from 'services/server/ServerHelpers';

export interface FetchJsonInit extends RequestInit {
  body?: BodyInit | null | any;
  headers?: HeadersInit;
}
export default async function fetchJson<T extends any>(
  input: RequestInfo,
  init: FetchJsonInit = {}
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    if (!init?.headers) {
      init.headers = {};
    }
    init.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...init.headers
    };

    init.credentials = 'include';
    //@ts-ignore

    //@ts-ignore
    init.headers.subdomain = getSubdomainFromHost();

    if (init.body) {
      if (typeof init.body !== 'string') {
        init.body = JSON.stringify(init.body);
      }
    }
    //@ts-ignore
    fetch(input, init)
      .then(async (response) => {
        if (response.ok) {
          const cookies = response.headers.get('set-cookie') || {};
          const result = await response.json();
          resolve({
            ...result,
            cookies
          });
        } else {
          const result = await response.json();
          reject(result);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

  // try {

  //   // if the server replies, there's always some data in json
  //   // if there's a network error, it will throw at the previous line
  //   const data = await response.json();

  //   if (response.ok) {
  //     return data;
  //   }

  //   const error: any = new Error(response.statusText);
  //   error.response = response;
  //   error.data = data;
  //   throw error;
  // } catch (error) {
  //   if (!error.data) {
  //     error.data = { message: error.message };
  //   }
  //   throw error;
  // }
}
