import { createContext, useContext } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import LocalStorage from 'services/LocalStorage';
import create from 'zustand';
import { Channel, User } from './types';

export const StoreContext = createContext(null);

export const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export type GlobalState = {
  me: User;
  setMe: (user: User) => void;
  user: User;
  setUser: (user: User) => void;
  channel: Channel;
  setChannel: (channel: Channel) => void;
  ownChannel: boolean;
  setOwnChannel: (ownChannel: boolean) => void;
  isCoeditor: boolean;
  setIsCoeditor: (isCoeditor: boolean) => void;
  currentAudio: H5AudioPlayer;
  setCurrentAudio: (currentAudio: H5AudioPlayer) => void;
  playlist: any[];
  setPlaylist: (playlist: any[]) => void;
};

export const useStore = create<GlobalState>((set) => ({
  me: undefined,
  setMe: (me) =>
    set((state) => {
      //LocalStorage.setItem("me", me)
      return { me };
    }),
  user: undefined,
  setUser: (user) => set((state) => ({ user })),
  channel: undefined,
  setChannel: (channel) => set((state) => ({ channel })),
  ownChannel: false,
  setOwnChannel: (ownChannel) => set((state) => ({ ownChannel })),
  isCoeditor: false,
  setIsCoeditor: (isCoeditor) => set((state) => ({ isCoeditor })),
  currentAudio: undefined,
  setCurrentAudio: (currentAudio: H5AudioPlayer) => set((state) => ({ currentAudio })),
  playlist: [],
  setPlaylist: (playlist: any[]) => set((state) => ({ playlist })),
}));
